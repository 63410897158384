<template>
  <div>
    <header class="shadow-lg z-20 relative">
      <div
        class="
          w-full
          bg-gray-800
          text-white
          flex
          justify-between
          items-center
          py-2.5
          px-8
        "
      >
        <div class="max-w-7xl mx-auto w-full flex justify-between items-center">
          <a
            href="mailto:sales@techy-panda.co.uk"
            class="text-sm hover:text-tp-grad-2 flex items-center"
          >
            <i class="fa-regular fa-envelope text-2xl mr-3"></i>
            sales@techy-panda.co.uk
          </a>
          <div>
            <ul class="flex">
              <li class="mx-3">
                <a
                  href="https://facebook.com/TechyPandaNotts/"
                  target="_blank"
                  class="hover:text-tp-grad-2"
                >
                  <i class="fa-brands fa-facebook-f text-xl"></i>
                </a>
              </li>
              <!-- <li class="mx-3">
                <a href="#" target="_blank" class="hover:text-tp-grad-2">
                  <i class="fa-brands fa-twitter text-xl"></i>
                </a>
              </li>
              <li class="mx-3">
                <a href="#" target="_blank" class="hover:text-tp-grad-2">
                  <i class="fa-brands fa-instagram text-xl"></i>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div
        class="
          w-full
          bg-white
          text-slate-800
          flex
          justify-between
          items-center
          md:px-8
        "
      >
        <div
          class="
            max-w-7xl
            mx-auto
            w-full
            flex
            justify-between
            items-center
            relative
          "
        >
          <div class="my-2 px-8 md:px-0">
            <router-link to="/">
              <img
                src="@/assets/img/logo.png"
                alt="Techy Panda Logo"
                class="w-36"
              />
            </router-link>
          </div>
          <div class="px-8 flex md:hidden">
            <span @click="toggleNav">
              <i
                class="
                  fa-solid fa-bars
                  text-xl text-slate-800
                  justify-center
                  items-center
                  rounded
                  border border-slate-800
                  px-3
                  py-2
                  hover:bg-slate-800 hover:text-white
                  cursor-pointer
                "
              ></i>
            </span>
          </div>
          <Nav ref="nav" />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import Nav from "./Nav.vue";

export default {
  components: {
    Nav,
  },
  methods: {
    toggleNav() {
      this.$refs.nav.toggleNav();
    },
  },
};
</script>

<style>
</style>