<template>
  <div class="bg-slate-900 w-full relative py-12">
    <div class="max-w-3xl mx-auto flex flex-col">
      <ul class="inline-flex justify-between mx-auto">
        <li>
          <a class="footer-icon" href="https://facebook.com/TechyPandaNotts/"
            ><i class="fa-brands fa-facebook-f"></i
          ></a>
        </li>
        <!-- <li>
          <a class="footer-icon"><i class="fa-brands fa-twitter"></i></a>
        </li>
        <li>
          <a class="footer-icon"><i class="fa-brands fa-instagram"></i></a>
        </li> -->
      </ul>

      <div class="flex mx-auto my-8">
        <img
          src="@/assets/img/logo-text-full-white.png"
          alt="Techy Panda Logo - White"
          class="w-48"
        />
      </div>

      <div class="flex flex-col mx-auto text-center text-slate-300">
        <p>Copyright &copy; 2022 Techy Panda</p>
        <p>All Rights Reserved. Website by Techy Panda</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>