<template>
  <div>
    <section class="bg-white py-20 lg:py-[120px] overflow-hidden relative z-10">
      <div class="container max-w-6xl mx-auto">
        <div class="flex flex-wrap lg:justify-between -mx-4">
          <div class="w-full lg:w-1/2 xl:w-6/12 px-4">
            <div class="max-w-[570px] mb-12 lg:mb-0 px-8">
              <span class="block mb-4 text-base text-tp-primary font-semibold">
                Contact Us
              </span>
              <h2
                class="
                  text-slate-800
                  mb-6
                  uppercase
                  font-bold
                  text-[32px]
                  sm:text-[40px]
                  lg:text-[36px]
                  xl:text-[40px]
                "
              >
                GET IN TOUCH WITH US
              </h2>

              <div class="flex mb-8 max-w-[370px] w-full">
                <div
                  class="
                    max-w-[60px]
                    sm:max-w-[70px]
                    w-full
                    h-[60px]
                    sm:h-[70px]
                    flex
                    items-center
                    justify-center
                    mr-6
                    overflow-hidden
                    bg-tp-primary bg-opacity-5
                    text-tp-primary
                    rounded
                  "
                >
                  <i class="fa-solid fa-phone"></i>
                </div>
                <div class="w-full">
                  <h4 class="font-bold text-slate-800 text-xl mb-1">
                    Phone Number
                  </h4>
                  <a class="text-base text-body-color" href="tel:07305435122"
                    >07305 435 122</a
                  >
                </div>
              </div>
              <div class="flex mb-8 max-w-[370px] w-full">
                <div
                  class="
                    max-w-[60px]
                    sm:max-w-[70px]
                    w-full
                    h-[60px]
                    sm:h-[70px]
                    flex
                    items-center
                    justify-center
                    mr-6
                    overflow-hidden
                    bg-tp-primary bg-opacity-5
                    text-tp-primary
                    rounded
                  "
                >
                  <i class="fa-regular fa-envelope text-2xl mr-3"></i>
                </div>
                <div class="w-full">
                  <h4 class="font-bold text-slate-800 text-xl mb-1">
                    Email Address
                  </h4>
                  <a
                    class="text-base text-body-color"
                    href="mailto:sales@techy-panda.co.uk"
                  >
                    sales@techy-panda.co.uk
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-1/2 xl:w-5/12 px-4">
            <div class="bg-white relative rounded-lg p-8 md:p-0">
              <form @submit.prevent="contact">
                <div class="mb-6">
                  <input
                    v-model="data.name"
                    type="text"
                    required
                    placeholder="Your Name*"
                    class="
                      w-full
                      rounded
                      py-3
                      px-[14px]
                      text-body-color text-base
                      border border-[f0f0f0]
                      outline-none
                      focus-visible:shadow-none
                      focus:border-tp-primary
                    "
                  />
                </div>
                <div class="mb-6">
                  <input
                    v-model="data.email"
                    type="email"
                    required
                    placeholder="Your Email*"
                    class="
                      w-full
                      rounded
                      py-3
                      px-[14px]
                      text-body-color text-base
                      border border-[f0f0f0]
                      outline-none
                      focus-visible:shadow-none
                      focus:border-tp-primary
                    "
                  />
                </div>
                <div class="mb-6">
                  <select
                    v-model="data.service"
                    placeholder="Chosen Service"
                    class="
                      w-full
                      rounded
                      py-3
                      px-[14px]
                      text-body-color text-base
                      border border-[f0f0f0]
                      outline-none
                      focus-visible:shadow-none
                      focus:border-tp-primary
                    "
                  >
                    <option value="N/A" selected disabled>
                      -- Choose a Service ---
                    </option>
                    <option value="Personal">Personal Website</option>
                    <option value="Business">Business Website</option>
                    <option value="E-Commerce">E-Commerce</option>
                  </select>
                </div>
                <div class="mb-6">
                  <textarea
                    v-model="data.message"
                    rows="6"
                    placeholder="Your Message*"
                    required
                    class="
                      w-full
                      rounded
                      py-3
                      px-[14px]
                      text-body-color text-base
                      border border-[f0f0f0]
                      resize-none
                      outline-none
                      focus-visible:shadow-none
                      focus:border-tp-primary
                    "
                  ></textarea>
                </div>
                <div>
                  <button
                    type="submit"
                    class="
                      w-full
                      text-white
                      bg-tp-primary
                      rounded
                      border border-tp-primary
                      p-3
                      transition
                      hover:bg-opacity-90
                    "
                  >
                    Send Message
                  </button>
                  <p
                    v-if="msg != ''"
                    class="block w-full text-center font-bold mt-2"
                  >
                    {{ msg }}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ====== Contact Section End -->
  </div>
</template>

<script>
import axios from "axios";

import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha("login");

      console.log(token);
    };

    return {
      recaptcha,
    };
  },
  meta: {
    title: "Contact Us | Techy Panda",
    metaTags: [
      {
        name: "description",
        content:
          "Contact Techy Panda today to get a free no obligation quote for all your website needs.",
      },
      {
        property: "og:description",
        content:
          "Contact Techy Panda today to get a free no obligation quote for all your website needs.",
      },
    ],
  },
  data() {
    return {
      data: {
        name: "",
        email: "",
        service: "N/A",
        message: "",
      },
      msg: "",
    };
  },
  methods: {
    contact() {
      let self = this;
      let msg = {
        to: "sales@techy-panda.co.uk",
        from: "sales@techy-panda.co.uk",
        subject: "Message from Techy Panda Website",
        html: `<strong>Name: </strong> ${this.data.name} <br/><strong>Email: </strong> ${this.data.email} <br/><strong>Service: </strong> ${this.data.service} <br/><strong>Message: </strong> ${this.data.message} <br/>`,
      };

      axios
        .post("https://api.techy-panda.co.uk/api/send-mail", msg)
        .then((response) => {
          if (response) {
            self.msg = "Thanks for your message. We will be in touch soon.";
            window.setTimeout(() => {
              location.reload();
            }, 1000);
            return;
          }
          self.msg = "We couldn't send your message. Please try again";
          return;
        });
    },
  },
};
</script>

<style>
</style>