<template>
  <div class="bg-slate-50 px-4">
    <div class="max-w-6xl w-full py-14 md:py-20 text-center mx-auto">
      <div class="block relative mx-auto block-title">
        <h2 class="">Our Solutions</h2>
        <h3 class="text-slate-500">Helping you start your online journey</h3>
      </div>

      <div class="w-full flex flex-wrap justify-between mt-14 md:mt-20">
        <Solution
          v-for="solution in solutions"
          :key="solution.title"
          :solution="solution"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Solution from "./Solution.vue";

export default {
  name: "SolutionsComponent",
  data() {
    return {
      solutions: [
        {
          title: "Web Design",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="-17 141 576 512" style="enable-background:new -17 141 576 512;" xml:space="preserve"><style type="text/css">	.st0{fill:url(#SVGID_1_);}</style><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-17" y1="397" x2="559" y2="397">	<stop offset="0" style="stop-color:#2C9FB8"></stop>	<stop offset="1" style="stop-color:#26E880"></stop></linearGradient><path class="st0" d="M495,141H47c-35.3,0-64,28.6-64,64v288c0,35.3,28.6,64,64,64h148.3l-9.6,48H135c-13.2,0-24,10.8-24,24 s10.8,24,24,24h272c13.3,0,24-10.8,24-24s-10.8-24-24-24h-50.7l-9.6-48H495c35.3,0,64-28.7,64-64V205C559,169.6,530.3,141,495,141z  M307.3,605h-72.6l9.6-48h53.5L307.3,605z M511,493c0,8.8-7.2,16-16,16H47c-8.8,0-16-7.2-16-16V205c0-8.8,7.2-16,16-16h448 c8.8,0,16,7.2,16,16V493z"></path></svg>',
          description:
            "A high-quality website to showcase your business and help you stand out from the crowd.",
          link: "/website-design",
        },
        {
          title: "Website Hosting",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="-49 141 512 512" style="enable-background:new -49 141 512 512;" xml:space="preserve"><style type="text/css">	.st0{fill:url(#SVGID_1_);}</style><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-49" y1="397" x2="463" y2="397">	<stop offset="0" style="stop-color:#2C9FB8"></stop>	<stop offset="1" style="stop-color:#26E880"></stop></linearGradient><path class="st0" d="M399,173H15c-35.3,0-64,28.6-64,64v64c0,35.4,28.6,64,64,64h384c35.4,0,64-28.6,64-64v-64 C463,201.6,434.3,173,399,173z M431,301c0,17.6-14.4,32-32,32H15c-17.6,0-32-14.4-32-32v-64c0-17.6,14.4-32,32-32h384 c17.6,0,32,14.4,32,32V301z M367,245c-13.3,0-24,10.7-24,24c0,13.3,10.8,24,24,24c13.3,0,24-10.8,24-24C391,255.7,380.3,245,367,245 z M271,245c-13.3,0-24,10.7-24,24c0,13.3,10.8,24,24,24c13.3,0,24-10.8,24-24C295,255.7,284.3,245,271,245z M399,429H15 c-35.3,0-64,28.6-64,64v64c0,35.3,28.6,64,64,64h384c35.4,0,64-28.7,64-64v-64C463,457.7,434.3,429,399,429z M431,557 c0,17.6-14.4,32-32,32H15c-17.6,0-32-14.4-32-32v-64c0-17.6,14.4-32,32-32h384c17.6,0,32,14.4,32,32V557z M367,501 c-13.3,0-24,10.7-24,24c0,13.3,10.8,24,24,24c13.3,0,24-10.8,24-24C391,511.7,380.3,501,367,501z M271,501c-13.3,0-24,10.7-24,24 c0,13.3,10.8,24,24,24c13.3,0,24-10.8,24-24C295,511.7,284.3,501,271,501z"></path></svg>',
          description:
            "Our cloud hosting has the best SSDs offering super-fast speeds, backups and unlimited traffic.",
          link: "/website-hosting",
        },
        {
          title: "Branding",
          icon: "custom",
          description:
            "Stand out from the crowd with professional branding for your business or products.",
          link: "/branding",
        },
        {
          title: "E-Commerce",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="14 141 640 512" style="enable-background:new 14 141 640 512;" xml:space="preserve"><style type="text/css">	.st0{fill:url(#SVGID_1_);}</style><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="12" y1="397" x2="649.7554" y2="397">	<stop offset="0" style="stop-color:#2C9FB8"></stop>	<stop offset="1" style="stop-color:#26E880"></stop></linearGradient><path class="st0" d="M76,165c0-13.3,10.8-24,24-24h72c11.5,0,21.4,8.2,23.6,19.5l1.5,12.5h420.7c20.3,0,36.5,20.3,30.8,40.7l-54,192 c-3.9,13.8-16.5,23.3-30.8,23.3H246.7l9.2,48H564c13.3,0,24,10.7,24,24s-10.7,24-24,24H235.1c-10.6,0-20.5-8.2-22.7-19.5L152.1,189 H100C86.8,189,76,178.3,76,165L76,165z M207.1,221l30.5,160h314l45-160H207.1z M300,605c0,26.5-21.5,48-48,48s-48-21.5-48-48 s21.5-48,48-48S300,578.5,300,605z M492,605c0-26.5,21.5-48,48-48s48,21.5,48,48s-21.5,48-48,48S492,631.5,492,605z M116,237 c13.3,0,24,10.7,24,24c0,13.3-10.7,24-24,24H36c-13.3,0-24-10.7-24-24c0-13.3,10.8-24,24-24H116z M132,317c13.3,0,24,10.7,24,24 s-10.7,24-24,24H36c-13.3,0-24-10.7-24-24s10.8-24,24-24H132z M148,397c13.3,0,24,10.7,24,24s-10.7,24-24,24H36 c-13.3,0-24-10.7-24-24s10.8-24,24-24H148z"></path></svg>',
          description:
            "A powerful but easy to use online shop, converting your visitors into new customers.",
          link: "/e-commerce",
        },
        {
          title: "SEO",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="14 141 640 512" style="enable-background:new 14 141 640 512;" xml:space="preserve"><style type="text/css">	.st0{fill:url(#SVGID_1_);}</style><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="46" y1="397" x2="622" y2="397">	<stop offset="0" style="stop-color:#2C9FB8"></stop>	<stop offset="1" style="stop-color:#26E880"></stop></linearGradient><path class="st0" d="M198,141h144c13.3,0,24,10.8,24,24v144c0,13.3-10.7,24-24,24s-24-10.7-24-24v-86.1L87,453.1 c-9.4,10.2-24.6,10.2-33.9,0c-9.4-8.5-9.4-23.7,0-33.1l231.1-231H198c-13.3,0-24-10.8-24-24S184.7,141,198,141L198,141z M622,221 c0,26.5-21.5,48-48,48s-48-21.5-48-48c0-26.5,21.5-48,48-48S622,194.5,622,221z M494,349c0,26.5-21.5,48-48,48s-48-21.5-48-48 s21.5-48,48-48S494,322.5,494,349z M398,477c0-26.5,21.5-48,48-48s48,21.5,48,48s-21.5,48-48,48S398,503.5,398,477z M494,605 c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S494,578.5,494,605z M622,605c0,26.5-21.5,48-48,48s-48-21.5-48-48 s21.5-48,48-48S622,578.5,622,605z M270,477c0-26.5,21.5-48,48-48s48,21.5,48,48s-21.5,48-48,48S270,503.5,270,477z M366,605 c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S366,578.5,366,605z M142,605c0-26.5,21.5-48,48-48s48,21.5,48,48 s-21.5,48-48,48S142,631.5,142,605z M622,477c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S622,450.5,622,477z M526,349 c0-26.5,21.5-48,48-48s48,21.5,48,48s-21.5,48-48,48S526,375.5,526,349z"></path></svg>',
          description:
            "Boost your website or shop to the top ranks with our custom SEO tools and dedicated site management.",
          link: "/seo",
        },
        {
          title: "Bespoke Development",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="14 141 640 512" style="enable-background:new 14 141 640 512;" xml:space="preserve"><style type="text/css">	.st0{fill:url(#SVGID_1_);}</style><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="45" y1="397" x2="621" y2="397">	<stop offset="0" style="stop-color:#2C9FB8"></stop>	<stop offset="1" style="stop-color:#26E880"></stop></linearGradient><path class="st0" d="M557,141H109c-35.3,0-64,28.6-64,64v288c0,35.3,28.7,64,64,64h148.3l-9.6,48H197c-13.2,0-24,10.8-24,24 s10.8,24,24,24h272c13.3,0,24-10.8,24-24s-10.8-24-24-24h-50.7l-9.6-48H557c35.3,0,64-28.7,64-64V205C621,169.6,592.3,141,557,141z  M296.7,605l9.6-48h53.5l9.6,48H296.7z M573,493c0,8.8-7.2,16-16,16H109c-8.8,0-16-7.2-16-16V205c0-8.8,7.2-16,16-16h448 c8.8,0,16,7.2,16,16V493z M407.1,278.9c-7.8-7.8-20.5-7.8-28.3,0s-7.8,20.5,0,28.3l33.9,33.8l-33.9,33.8c-7.8,7.8-7.8,20.5,0,28.3 c4,3.9,9.1,5.8,14.2,5.8s10.2-1.9,14.1-5.8l48-48c7.8-7.8,7.8-20.5,0-28.3L407.1,278.9z M287.1,278.9c-7.8-7.8-20.5-7.8-28.3,0 l-48,48c-7.8,7.8-7.8,20.5,0,28.3l48,48c4,3.9,9.1,5.8,14.2,5.8s10.2-1.9,14.1-5.8c7.8-7.8,7.8-20.5,0-28.3L253.3,341l33.8-33.8 C294.1,299.4,294.1,286.7,287.1,278.9z"></path></svg>',
          description:
            "Fully custom software solutions to help your business better manage day-to-day operations.",
          link: "/bespoke-development",
        },
      ],
    };
  },
  components: {
    Solution,
  },
};
</script>

<style>
</style>