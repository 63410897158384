<template>
  <div class="bg-slate-50">
    <div
      class="
        max-w-6xl
        w-full
        py-14
        md:py-20
        text-center
        mx-auto
        px-2
        sm:px-0
        md:px-4
      "
    >
      <div class="block relative mx-auto block-title">
        <h2 class="text-5xl font-semibold">3 Simple Web Packages</h2>
        <h3 class="text-2xl text-slate-500 font-medium">
          Choose a package that suits you or your business
        </h3>
      </div>
      <div class="inline-flex justify-between mt-8 items-center">
        <h3
          class="mr-2 font-bold text-lg"
          :class="{ 'text-blue-500': pricing === false }"
        >
          Monthly Price
        </h3>
        <label class="switch">
          <input type="checkbox" v-model="pricing" />
          <span class="slider round"></span>
        </label>
        <h3
          class="ml-2 font-bold text-lg"
          :class="{ 'text-blue-500': pricing === true }"
        >
          One-Off Price <span class="text-green-500 text-xs">(Best Value)</span>
        </h3>
      </div>

      <div
        class="w-full flex flex-wrap justify-center md:justify-between mt-20"
      >
        <WebPackage
          v-for="(webPackage, index) in webPackages"
          :key="webPackage.title"
          :webPackage="webPackage"
          :index="index"
        />
      </div>

      <div class="mt-8 md:mt-24">
        <!-- <a href="/get-started" class="btn clear">Get Started Now</a> -->
        <p class="text-slate-600 mt-4 text-xs">
          &ast; Price is subject to complexity and requirements or the project.
          No hidden fees, we will give you a quote before starting any work.
          Minimum 12 months contract.
        </p>
      </div>
    </div>

    <div
      class="
        w-full
        flex flex-wrap
        justify-center
        md:justify-between
        mt-8
        bg-slate-800
      "
    >
      <div
        class="
          max-w-6xl
          w-full
          py-14
          md:py-20
          text-center
          mx-auto
          px-2
          sm:px-0
          md:px-4
        "
      >
        <div class="block relative mx-auto block-title">
          <h2 class="text-5xl font-semibold text-white">
            All Our Websites Include...
          </h2>
        </div>

        <div
          class="w-full flex flex-wrap justify-center md:justify-between mt-14"
        >
          <Included
            v-for="(solution, index) in solutions"
            :key="solution.title"
            :solution="solution"
            :index="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebPackage from "./WebPackage.vue";
import Included from "./Included.vue";

export default {
  name: "WebPackages",
  components: {
    WebPackage,
    Included,
  },
  data() {
    return {
      webPackages: [
        {
          title: "Personal",
          description: "Perfect for your own portfolio",
          price: "200",
          size: "large",
          items: [
            "A single page website is perfect for showcasing yourself or your small business. Whether it's a portfolio for you or to guide customers to buy your products.",
          ],
          link: "/select-plan/personal",
        },
        {
          title: "Business",
          description: "Get your business seen online",
          price: "500",
          size: "large",
          items: [
            "This package offers the best representation of your business to you and your clients, making it perfect for both small and large businesses. The content, which can include a business description, gallery, and services, will be tailored to your needs.",
          ],
          link: "/select-plan/business",
        },
        {
          title: "E-Commerce",
          description: "Open your online shop",
          price: "1000",
          size: "large",
          items: [
            "Outstanding online stores. Display your products at their best. Allow visitors to navigate your products, add items to their shopping cart, check out quickly and easily, and leave product reviews.",
          ],
          link: "/select-plan/e-commerce",
        },
      ],
      solutions: [
        {
          title: "Unique Designs",
          description:
            "We don't believe in using templates. All of our websites are designed specifically to suit your business.",
        },
        {
          title: "Mobile Friendly Design",
          description:
            "Our Mobile Friendly Design allows your website to be viewed across a wide range of handheld devices.",
        },
        {
          title: "Basic SEO Optimisation",
          description:
            "We'll kickstart your website with everything it needs to start ranking on the most popular search engines.",
        },
        {
          title: "Free Technical Support",
          description:
            "Our dedicated technical team are always happy to help with any queries or questions.",
        },
        {
          title: "Satisfaction Guarantee",
          description:
            "We will work with you endlessly until you are 100% happy with all aspects of your website.",
        },
        {
          title: "Free Quotation",
          description:
            "Our team of experts look forward to meeting you to discuss your business and website goals.",
        },
      ],
      pricing: true,
    };
  },
  watch: {
    pricing(newVal) {
      if (newVal) {
        this.webPackages.forEach((thisPackage, index) => {
          switch (index) {
            case 0:
              thisPackage.price = "200";
              break;
            case 1:
              thisPackage.price = "500";
              break;
            case 2:
              thisPackage.price = "1000";
              break;
          }
          thisPackage.size = "large";
        });
      } else {
        this.webPackages.forEach((thisPackage, index) => {
          switch (index) {
            case 0:
              thisPackage.price = "25/month";
              break;
            case 1:
              thisPackage.price = "50/month";
              break;
            case 2:
              thisPackage.price = "120/month";
              break;
          }
          thisPackage.size = "small";
        });
      }
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>