<template>
  <div class="gradient-bg bg-banner relative">
    <div
      class="
        flex flex-col
        sm:flex-row
        items-center
        max-w-2xl
        md:max-w-3xl
        py-10
        sm:py-12
        mx-auto
        w-full
        justify-around
        z-10
        relative
      "
    >
      <h2 class="mb-6 sm:mb-0 text-xl md:text-2xl font-bold">
        Need a Website? We can help!
      </h2>
      <!-- <a href="#" class="btn clear">Learn More</a> -->
      <!-- <router-link to="/contact-us" class="btn clear">Get In Touch</router-link> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>