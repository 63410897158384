<template>
  <div class="bg-slate-100 z-10 py-20 relative px-8 home-main-container">
    <div class="flex items-center max-w-7xl mx-auto h-full flex-col justify-center md:flex-row">
      <div class="home-main-left flex flex-col md:block">
        <p class="text-center md:text-left text-tp-primary md:text-medium xl:text-lg mb-6 block">Dedicated to helping your business</p>
        <h1 class="text-center md:text-left text-3xl sm:text-5xl md:text-5xl xl:text-6xl font-bold text-slate-800 big-title">A Group of Web and Design <span class="custom-grad">Experts</span></h1>
        <img src="@/assets/img/small-divide.png" alt="Small Divide" class="mx-auto md:mx-0 my-8">
        <p class="hidden sm:block text-center md:text-left md:pr-20 xl:pr-24">Our passion in design and creativity allows us to offer a huge range of quality services.</p>
        <router-link to="/contact-us" class="btn primary mt-4 sm:mt-12 mx-auto md:mx-0">Contact Us</router-link>
      </div>

      <div class="hidden md:block home-main-right">
        <img src="@/assets/img/web-background.png" alt="Main Background Image" class="main-bg-image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainComponent'
}
</script>

<style>

</style>