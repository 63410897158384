<template>
  <div class="w-full sm:w-1/2 md:w-1/3 px-3 lg:px-8 mb-10">
    <span
      :href="solution.link"
      class="
        bg-white
        shadow-lg
        border-2 border-transparent
        hover:border-tp-primary hover:border-opacity-50
        rounded-lg
        px-2
        py-12
        flex flex-col
        items-center
        w-full
        transition-all
        duration-300
      "
    >
      <span
        v-html="solution.icon"
        class="w-20 h-20"
        style="font-size: 80px"
        v-if="solution.icon != 'custom'"
      ></span>
      <span class="w-20 h-20" style="font-size: 80px" v-else
        ><img
          src="@/assets/img/icon-only.png"
          alt="Techy Panda Icon"
          width="80"
      /></span>
      <h3
        class="
          text-xl
          md:text-2xl
          font-semibold
          text-slate-800
          my-2
          solution-title
        "
      >
        {{ solution.title }}
      </h3>
      <h4 class="px-2 text-sm leading-5 solution-desc" style="">
        {{ solution.description }}
      </h4>

      <!-- <a :href="solution.link" class="btn primary mt-8">Read More</a> -->
    </span>
  </div>
</template>

<script>
export default {
  name: "SolutionComponent",
  props: {
    solution: {
      type: Object,
      default: function () {},
    },
  },
};
</script>

<style>
</style>