<template>
  <div class="gradient-bg-2 bg-banner relative">
    <div class="flex flex-col sm:flex-row items-center max-w-2xl md:max-w-3xl py-10 sm:py-12 mx-auto w-full justify-around z-10 relative">
      <h2 class="px-4 sm:px-0 text-center sm:text-left mb-6 sm:mb-0 text-xl md:text-2xl font-bold">Get in touch today to start your online journey!</h2>
      <!-- <a href="#" class="btn clear">Contact Us</a> -->
      <router-link to="/contact-us" class="btn clear">Contact Us</router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>