import { createWebHistory, createRouter } from 'vue-router'
import Home from '@/views/index.vue'
import Contact from '@/views/contact-us.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: Contact,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  let title = to.name + ' | Techy Panda'
  if (to.name == 'Home') {
    title = 'Welcome To Techy Panda'
  }
  document.title = title
  next()
})

export default router
