<template>
  <div
    class="
      top-full
      absolute
      w-full
      h-0
      md:h-auto
      overflow-hidden
      md:overflow-auto
      transition-all
      bg-slate-800
      md:bg-gray-50 md:w-auto md:bg-transparent md:relative
    "
    :style="[mobileNav && !desktop ? 'height: 420px' : '']"
  >
    <ul class="flex flex-col md:flex-row items-center py-4 md:py-0">
      <li class="max-w-sm sm:max-w-md w-full md:w-auto mx-3">
        <router-link
          to="/"
          class="nav-link"
          :class="{ 'active-nav': $route.path == '/' }"
          >Home</router-link
        >
      </li>
      <!-- <li class="max-w-sm sm:max-w-md w-full md:w-auto mx-3">
        <router-link to="/about-us" class="nav-link" :class="{ 'active-nav' : $route.path == '/about-us'}">About Us</router-link>
      </li>
      <li class="max-w-sm sm:max-w-md w-full md:w-auto mx-3">
        <router-link to="/services" class="nav-link" :class="{ 'active-nav' : $route.path == '/services'}">Services</router-link>
      </li>
      <li class="max-w-sm sm:max-w-md w-full md:w-auto mx-3">
        <router-link to="/showcase" class="nav-link" :class="{ 'active-nav' : $route.path == '/showcase'}">Showcase</router-link>
      </li>
      <li class="max-w-sm sm:max-w-md w-full md:w-auto mx-3">
        <router-link to="/blog" class="nav-link" :class="{ 'active-nav' : $route.path == '/blog'}">Blog</router-link>
      </li> -->
      <li class="max-w-sm sm:max-w-md w-full md:w-auto mx-3">
        <router-link
          to="/contact-us"
          class="nav-link"
          :class="{ 'active-nav': $route.path == '/contact-us' }"
          >Contact Us</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AppNav',
  data() {
    return {
      desktop: true,
      mobileNav: false,
      width: window.innerWidth,
    };
  },
  mounted() {
    let self = this;
    window.addEventListener("resize", this.getDimensions);
    window.setTimeout(() => {
      self.checkDesktop();
    }, 200);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  methods: {
    getDimensions() {
      this.width = window.innerWidth;
      this.checkDesktop();
    },
    checkDesktop() {
      if (this.width > 980) {
        this.desktop = true;
        this.mobileNav = false;
      } else {
        this.desktop = false;
      }
    },
    toggleNav() {
      this.mobileNav = !this.mobileNav;
    },
  },
};
</script>

<style>
</style>