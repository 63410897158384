<template>
  <div>
    <AppHeader />
    <router-view />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  created() {
    let fontAwesomeScript = document.createElement('script')
    fontAwesomeScript.setAttribute('src', 'https://kit.fontawesome.com/6ac09d14e8.js')
    document.head.appendChild(fontAwesomeScript)
  },
  components: {
    AppHeader,
    AppFooter
}
}
</script>

<style>
</style>
