import { createApp } from 'vue'
import App from './App.vue'

import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGtag from 'vue-gtag'

import 'regenerator-runtime/runtime'

import './main.css'

import router from './router'

createApp(App)
  .use(router)
  .use(VueGtag, {
    config: { id: 'G-XCWRMDEHB4' },
    router,
    enabled: false,
  })
  .use(VueReCaptcha, {
    siteKey: '6LdND6EhAAAAAEPowQNIEsGEbx6Gmmu4IUUiWQvE',
  })
  .mount('#app')
