<template>
  <div>
    <Main />
    <NeedAWebsite />
    <Solutions />
    <WeLoveWhatWeDo />
    <WebPackages />
    <GetInTouch />
  </div>
</template>

<script>
import Main from "../components/Home/Main.vue";
import Solutions from "../components/Home/Solutions.vue";
import WeLoveWhatWeDo from "../components/Home/WeLoveWhatWeDo.vue";
import WebPackages from "../components/Home/WebPackages.vue";

import NeedAWebsite from "../components/NeedAWebsite.vue";
import GetInTouch from "../components/GetInTouch.vue";

export default {
  name: "IndexPage",
  meta: {
    title: "Welcome To Techy Panda",
    metaTags: [
      {
        name: "description",
        content:
          "High Quality Web Design and Development Services in Worksop, Nottinghamshire. With over 5 years of experience.",
      },
      {
        property: "og:description",
        content:
          "High Quality Web Design and Development Services in Worksop, Nottinghamshire. With over 5 years of experience.",
      },
    ],
  },
  components: {
    Main,
    NeedAWebsite,
    Solutions,
    WeLoveWhatWeDo,
    WebPackages,
    GetInTouch,
  },
};
</script>

<style>
</style>