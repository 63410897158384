<template>
  <div
    class="
      block
      mb-8
      md:mb-0
      w-full
      sm:w-1/2
      px-4
      md:px-0 md:w-1/3
      max-w-lg
      sm:max-w-none
    "
  >
    <div
      class="rounded-lg bg-white shadow-lg overflow-hidden"
      :class="'web-package-' + (index + 1)"
    >
      <div class="py-8 wp-gradient">
        <h2 class="text-2xl xl:text-3xl font-bold">{{ webPackage.title }}</h2>
        <h3 class="text-sm xl:text-base font-medium">
          {{ webPackage.description }}
        </h3>
      </div>

      <div class="bg-white px-4 py-8">
        <div class="relative inline-flex">
          <span class="absolute top-0 -left-4 font-bold text-lg xl:text-xl">
            &pound;
          </span>
          <h3
            v-if="webPackage.size == 'small'"
            class="text-4xl xl:text-5xl font-bold"
          >
            {{ webPackage.price }}
          </h3>
          <h3 v-else class="text-5xl xl:text-6xl font-bold">
            {{ webPackage.price }}
          </h3>
          <span class="absolute top-0 -right-1 font-bold"> &ast; </span>
        </div>

        <div class="my-8 xl:my-12 mx-auto">
          <ul class="w-full px-4 web-package-desc">
            <li
              v-for="(item, index) in webPackage.items"
              :key="item"
              class="flex items-center justify-center pb-3 flex-wrap"
            >
              <!-- <svg class="svg-inline--fa fa-check" aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M440.1 103C450.3 112.4 450.3 127.6 440.1 136.1L176.1 400.1C167.6 410.3 152.4 410.3 143 400.1L7.029 264.1C-2.343 255.6-2.343 240.4 7.029 231C16.4 221.7 31.6 221.7 40.97 231L160 350.1L407 103C416.4 93.66 431.6 93.66 440.1 103V103z"></path></svg> -->
              <p class="text-sm xl:text-base">{{ item }}</p>
              <hr
                v-if="index !== webPackage.items.length - 1"
                class="w-full h-0.5 bg-slate-200 mt-3 mx-12"
              />
            </li>
          </ul>
        </div>

        <div class="mt-4">
          <!-- <a :href="webPackage.link" class="btn wp-gradient">Buy Now</a> -->
          <router-link to="/contact-us" class="btn wp-gradient"
            >Buy Now</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebPackage",
  props: {
    webPackage: {
      type: Object,
      default: function () {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style>
</style>