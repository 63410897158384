<template>
  <div
    class="bg-slate-100 z-10 py-14 md:py-32 relative px-8"
    style="background-color: #281e29"
  >
    <div
      class="
        flex flex-col-reverse
        md:flex-row
        items-center
        max-w-8xl
        mx-auto
        h-full
        text-white
        justify-between
        text-center
      "
    >
      <div class="w-full md:w-7/12 max-w-2xl md:max-w-none">
        <div class="w-full flex flex-wrap justify-between">
          <LoveItem v-for="item in items" :key="item.title" :item="item" />
        </div>
      </div>
      <div
        class="
          flex
          items-center
          max-w-xl
          md:max-w-7xl
          mx-auto
          h-full
          flex-col
          justify-center
          md:flex-row
          w-full
          md:w-5/12 md:ml-10 md:mr-2
          mb-14
          md:mb-0
        "
      >
        <div class="flex flex-col md:block">
          <p class="text-center md:text-medium xl:text-lg mb-6 block">
            Dedicated to helping your business
          </p>
          <h2
            class="
              text-center text-4xl
              sm:text-5xl
              md:text-5xl
              xl:text-6xl
              font-bold
              big-title
              px-8
            "
          >
            We <span class="custom-grad">Love</span> What We Do
          </h2>
          <img
            src="@/assets/img/small-divide.png"
            alt="Small Divide"
            class="mx-auto my-8"
          />
          <p class="text-sm sm:text-base text-center">
            We put pure creativity and passion into everything we do and form
            strong relationships in order to understand you and the markets your
            business is trying to reach.
          </p>
          <!-- <a href="#" class="btn clear-white mt-4 sm:mt-12 mx-auto md:mx-0">Get Started</a> -->
          <!-- <router-link to="/contact-us" class="btn clear-white mt-4 sm:mt-12 mx-auto md:mx-0">Contact Us</router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoveItem from "./LoveItem.vue";

export default {
  name: "WeLove",
  components: {
    LoveItem,
  },
  data() {
    return {
      items: [
        {
          title: "Happy Customers",
          icon: '<i class="fa-regular fa-users" style="vertical-align: 1em"></i>',
          data: "10+",
          size: [90, 72],
        },
        {
          title: "Years Experience",
          icon: '<i class="fa-regular fa-calendar-range" style="vertical-align: 1em"></i>',
          data: "5+",
          size: [80, 80],
        },
        {
          title: "5 Star Reviews",
          icon: '<i class="fa-regular fa-stars" style="vertical-align: 1em"></i>',
          data: "5+",
          size: [80, 80],
        },
        {
          title: "Renewable Energy",
          icon: '<i class="fa-solid fa-wind-turbine" style="vertical-align: 1em"></i>',
          data: "100%",
          size: [80, 80],
        },
      ],
    };
  },
};
</script>

<style>
</style>