<template>
  <div class="w-full sm:w-1/2 mb-10 flex justify-center">
    <div class="border rounded border-white  border-opacity-60 w-64 h-60 md:w-56 md:h-52 xl:w-72 xl:h-64 flex flex-col items-center relative py-8 love-item" style="background-color: #281e29">
        <span v-html="item.icon" class="opacity-60 z-10 love-icon" style="font-size: 90px;" :style="'font-size: ' + item.size[1] + 'px; width: ' + item.size[0]  + 'px; height: '+item.size[1] + 'px;'"></span>
        <h3 class="text-5xl md:text-4xl xl:text-5xl font-semibold text-white z-10 mt-4">{{item.data}}</h3>
        <h3 class="text-xl md:text-lg xl:text-xl font-semibold text-whiet z-10 mt-2">{{item.title}}</h3>
        <span class="absolute h-20 z-0 bottom-1/2 translate-y-1/2" style="width: calc(100% + 2px); left: -1px; background-color: #281e29"></span>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'LoveItem',
  props: {
    item: {
      type: Object,
      default: function() {}
    }
  }
}
</script>

<style>

</style>